import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

const el = document.createElement('pwa-update');
document.body.appendChild(el);

// For more info on the @pwabuilder/pwainstall component click here https://github.com/pwa-builder/pwa-install
import '@pwabuilder/pwainstall';

@customElement('app-home')
export class AppHome extends LitElement {

// For more information on using properties and state in lit
// check out this link https://lit.dev/docs/components/properties/
@property() message = 'Welcome!';

static get styles() {
return css`
body {
  padding: 0;
  margin: 0;
  }
  br {
  display: none;
  }
  header {
  display: none !important;
  }
  body.responsive.layout-full #page-wrapper .full-container {
  max-width: 100% !important;
  }
  #colophon.site-footer {
  display: none;
  }
  #secondary {
  display: none;
  }
  .fl-row-content-wrap {
  padding: 0;
  }
  .pwa-wrapper {
    height: 100vh;
    background: #000;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
  .pwa-wrapper .social {
    height: 50px;
    border-bottom: 1px #222 solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pwa-wrapper .social .social-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 7px;
  }
  .pwa-wrapper .social .social-item img{
    width: 20px;
    max-width: 100%;
  }
  .pwa-wrapper .leadspace {
    padding: 30px;
  }
  .pwa-wrapper .leadspace img {
    width: 100%;
  }
  .pwa-wrapper .footer {
    background: #dd0c34;
    width: 100%;
    margin-top: auto;
  }
  .pwa-wrapper .footer .contact {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .pwa-wrapper .footer .contact .contact-a {
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px #000 solid;
    width: 33.33333%;
    text-align: center;
  }
  .pwa-wrapper .footer .contact .contact-a:first-child {
    border-left: 1px #000 solid;
  }
  .pwa-wrapper .footer .contact .contact-a img {
    width: 30px;
    max-width: 100%;
  }
  .pwa-wrapper .radio-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }
  .pwa-wrapper .radio-wrapper .radio-player {
    positio; relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(160,0,0,1) 0%, rgba(0,0,0,1) 100%) !important;
    padding: 15px;
    box-sizing: border-box;
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap {
    background: #3b3b3b;
    border: 5px solid #aeaeae;
    display: flex;
    justify-content: center;
    align-content: baseline;
    flex-wrap: wrap;
    position: absolute;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    max-width: 100%;
    box-shadow: 0px 0px 60px 30px rgb(0, 0, 0, 0.35);
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner h3 {
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-family: khand;
    margin: 0;
    margin-top: 10px;
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner img {
    width: 60px;
    height: 50px;
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner .live {
    background-color: #ff0000;
    color: white;
    font-size: 10px;
    font-family: khand;
    padding: 0px 8px;
    border-radius: 4px;
  }
  .pwa-wrapper .radio-wrapper .radio-player audio{
    border-radius: 50%;
    width: 220px;
    height: 120px;
    max-width: 100%;
  }
  audio::-webkit-media-controls-panel{
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    height: 250px;
  }
  audio::-webkit-media-controls-mute-button {
    background-color: #aeaeae;
    border-radius: 50%;
  }
  audio::-webkit-media-controls-play-button {
    background-color: #aeaeae;
    border-radius: 50%;
  }
  audio::-webkit-media-controls-timeline-container {
    background-color: #aeaeae !important;
  }

  @media (max-width: 767px){
  .pwa-wrapper .radio-wrapper {
    padding: 0 !important;
    width: 100% !important;
  }
  // .radio-player .radio_player {
  //   width: 100% !important;
  // }
  // }

  @media (min-width: 400px) {
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap {
    width: 340px;
    height: 340px;
    }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner {
    padding-top: 45px;
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner img {
    width: 100px;
    height: 80px;
  }
  .pwa-wrapper .radio-wrapper .radio-player .details-wrap .details-inner h3 {
    font-size: 18px;
    margin-top: 125px;
  }
  .pwa-wrapper .radio-wrapper .radio-player audio {

  }
  audio::-webkit-media-controls-panel{

  }


`;
}

constructor() {
super();
}

async firstUpdated() {
// this method is a lifecycle even in lit
// for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
console.log('This is your home page');
}

share() {
if ((navigator as any).share) {
(navigator as any).share({
title: 'The Generator FM',
text: "It's Rock You Live On",
url: 'https://thegenerator.co.nz',
});
}
}

render() {
return html`
<div>
  <div class="pwa-wrapper">
      <div class="radio-wrapper">
        <div class="social">
          <a class="social-item" href="https://www.facebook.com/GeneratorFM">
            <img src="https://thegenerator.co.nz/wp-content/uploads/2022/05/facebook-grey.png" alt="The Generator on Facebook">
          </a>
          <a class="social-item" href="https://www.instagram.com/thegeneratorfm">
            <img src="https://thegenerator.co.nz/wp-content/uploads/2022/05/instagram-grey.png" alt="The Generator on Instagram">
          </a>
          <a class="social-item" href="https://www.twitter.com/thegeneratorfm">
            <img src="https://thegenerator.co.nz/wp-content/uploads/2022/05/twitter-grey.png" alt="The Generator on Twitter">
          </a>
        </div>
        <div class="leadspace">
          <img src="https://thegenerator.co.nz/wp-content/uploads/2022/06/grey-logo.png" alt="THE GENERATOR">
        </div>
        <div class="radio-player">
          <div class="details-wrap">
            <div class="details-inner">
              <img src="https://thegenerator.co.nz/wp-content/uploads/2022/04/logo.png" class="radio-player-thumbnail" alt="The Generator FM">
              <h3 class="station-title">The Generator FM</h3>
              <div class="live">LIVE<span class="blinker"></span></div>
            </div>
          </div>
          <audio controls autoplay src="https://s1.myradiostream.com/14268/listen.mp3"></audio>
        </div>
        <div class="footer">
          <div class="contact">
            <a class="contact-a" href="tel:0800762537">
              <img src="https://thegenerator.co.nz/wp-content/uploads/2022/05/phone.png" alt="Phone">
            </a>
            <a class="contact-a" href="mailto:studio@thegenerator.co.nz">
              <img src="https://thegenerator.co.nz/wp-content/uploads/2022/05/email.png" alt="Email">
            </a>
            <a class="contact-a" href="sms:2739">
              <img src="https://thegenerator.co.nz/wp-content/uploads/2022/05/text.png" alt="Text">
            </a>
          </div>
        </div>
      </div>
    </div>
</div>
`;
}
}